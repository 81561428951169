import { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { currencyPrefix } from 'bv';
import TranslationsCtx from '../../translations_context';

const Amount = ({
  input,
  meta: { dirty, error, active },
  periodIsNotSelected,
  disabled,
}) => {
  const tNamespace = useContext(TranslationsCtx) || 'stake_limits';

  let errorMsg;

  if (periodIsNotSelected && active) {
    errorMsg = t(`${tNamespace}.form.errors.amount.choose_timeframe_first`);
  } else if (error && dirty) {
    errorMsg = error;
  }

  const inputClassName = classnames('bvs-form-control', {
    error: errorMsg,
  });

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        type="text"
        name="amount"
        className={inputClassName}
        disabled={disabled}
      />
      <span className="currency">{disabled ? '-' : currencyPrefix()}</span>
      {errorMsg && (
        <span className="bvs-form-error-msg">
          {errorMsg}
        </span>
      )}
    </div>
  );
};

Amount.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  periodIsNotSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

Amount.defaultProps = {
  disabled: false,
};

export default Amount;
