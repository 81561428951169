import { createSelector } from 'reselect';
import { mandatoryForLicence, availableForLicence } from 'CashierCommon/helpers';
import { getLimitOptions } from 'DepositLimits/selectors';
import { depositDomain } from './deposit';

export const showDepositLimitModal = createSelector(
  depositDomain,
  (substate) => substate.showModal.depositLimit,
);

export const showDepositLimitHeader = createSelector(
  getLimitOptions,
  availableForLicence,
);

export const getDepositLimitIsUpdating = createSelector(
  depositDomain,
  (substate) => substate.depositLimitIsUpdating,
);

export const isLimitMandatory = createSelector(
  getLimitOptions,
  mandatoryForLicence,
);
