export const SET_PENDIG_WITHDRAWS = 'cashierActions/withdraw/SET_PENDIG_WITHDRAWS';
export const REMOVE_PENDING_WITHDRAW = 'cashierActions/withdraw/REMOVE_PENDING_WITHDRAW';
export const LOADING = 'cashierActions/withdraw/LOADING';

export const setPendingWithdraws = (withdraws) => ({
  type: SET_PENDIG_WITHDRAWS,
  withdraws,
});

export const toggleLoader = (loading) => ({
  type: LOADING,
  loading,
});

export const removeWithDraw = (item) => ({
  type: REMOVE_PENDING_WITHDRAW,
  item,
});

export const initialState = {
  withdraws: [],
  withdrawalsList: [],
  loading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PENDIG_WITHDRAWS:
      return { ...state, withdraws: action.withdraws };
    case REMOVE_PENDING_WITHDRAW:
      return {
        ...state,
        withdraws: state.withdraws.filter(
          (item) => item.transaction_id !== action.item.transaction_id,
        ),
      };
    case LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};
