import { compose } from 'underscore';
import { t } from 'bv-i18n';
import { refresh as refreshBalance } from 'bv-services/account-balance';
import { deleteWithdraw, fetchPendingWithdrawals } from 'CashierCommon/api/withdraw_api';
import { WITHDRAW, showFetchError, showSuccessMessage } from 'CashierCommon/helpers';
import { fetchPaymentMethods } from './thunks';
import { removeWithDraw, setPendingWithdraws, toggleLoader } from './withdraw_store';

export const fetchWithdrawals = () => fetchPaymentMethods(WITHDRAW);

export const fetchPendingWithdraws = () => (dispatch) => (
  fetchPendingWithdrawals()
    .then(compose(dispatch, setPendingWithdraws))
);

export const deleteWithDraw = (item) => (dispatch) => {
  dispatch(toggleLoader(true));
  return deleteWithdraw(item)
    .then((response) => {
      dispatch(toggleLoader(false));
      if (response.success) {
        refreshBalance();
        showSuccessMessage(t('withdrawal_voided'));
        dispatch(removeWithDraw(item));
        return;
      }
      showFetchError(t('cannot_cancel_withdrawal'));
    })
    .catch(() => {
      dispatch(toggleLoader(false));
    });
};
