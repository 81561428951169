import { v as bvVar } from 'bv';

const { payButtonColor } = bvVar('feDeposit');

const PayButton = () => (
  <button
    type="submit"
    className={`apple-pay-button apple-pay-button--${payButtonColor}`}
  />
);

export default PayButton;
