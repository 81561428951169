import { v as bvVar, getCookie } from 'bv';
import { getCurrencyCode } from 'bv-helpers/price';

const { PaymentRequest } = window;
const countryCode = getCookie('country_code');
const currencyCode = getCurrencyCode();

const { debitOnlyCountries } = bvVar('feDeposit');
const { merchantIdentifier } = bvVar('applePay');

const methodData = [{
  supportedMethods: 'https://apple.com/apple-pay',
  data: {
    version: 4,
    merchantIdentifier,
    merchantCapabilities: [
      'supports3DS',
      ...(debitOnlyCountries.includes(countryCode) ? ['supportsDebit'] : []),
    ],
    supportedNetworks: ['visa', 'masterCard', 'electron', 'maestro'],
    countryCode,
  },
}];

const details = ({ value }) => ({
  total: {
    label: bvVar('siteName'),
    amount: { currency: currencyCode, value },
  },
});

export const paymentRequest = ({ value }) => new PaymentRequest(
  methodData,
  details({ value }),
);

export const isSupported = async () => {
  if (!PaymentRequest) return false;
  return await paymentRequest({ value: '0.01' })?.canMakePayment() ?? false;
};
