import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { currencyPrefix } from 'bv';

import { Button } from 'bv-components';
import { deleteWithDraw as deleteWithdrawAction } from 'CashierActions/ducks/withdraw_thunks';
import { selectLoading } from 'CashierActions/selectors/withdraw';
import { t } from 'bv-i18n';

const WithdrawItem = ({
  deleteWithDraw,
  withdraw,
  loading,
}) => {
  const [disabled, toggleButton] = useState(false);
  const dateDisplaySettings = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const deleteWithDrawItem = () => {
    if (!disabled) {
      toggleButton(true);
      deleteWithDraw(withdraw);
    }
  };

  useEffect(() => {
    if (!loading) {
      toggleButton(false);
    }
  }, [loading]);

  const date = new Date(withdraw.timestamp);
  return (
    <div className="bvs-msg-box pending-withdraws-item">
      <strong className="pending-withdraws-item__title">
        {`${currencyPrefix()} ${Number(withdraw.amount).toFixed(2)}`}
      </strong>
      {date.toLocaleDateString('en-gb', dateDisplaySettings)}
      <div className="pending-withdraws-item__fundsdata">
        <div className="pending-withdraws-item__details">
          {t(`cashier.payments.payment_method.${withdraw.paysol_name}`, { default: withdraw.paysol_name })}
        </div>
        <div className="pending-withdraws-item__details">{withdraw.transaction_id}</div>
      </div>
      <Button
        onClick={deleteWithDrawItem}
        label={t('cancel_this_withdrawal')}
        primary
        loading={disabled && loading}
      />
    </div>
  );
};

WithdrawItem.propTypes = {
  withdraw: PropTypes.instanceOf(Object).isRequired,
  deleteWithDraw: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteWithDraw: compose(dispatch, deleteWithdrawAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawItem);
