import { useSelector } from 'react-redux';
import { useField, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { LOSS_LIMIT_FORM as FORM } from 'CashierCommon/helpers';
import { periodHasPendingLimit } from 'CashierActions/selectors/loss_limit';
import Period from '../period';

const LossLimitPeriod = ({ period }) => {
  const { values: { type } } = useFormState();
  const {
    input: {
      onChange,
      value: selectedPeriod,
    },
  } = useField(FORM.fields.period);

  const hasPending = useSelector((state) => periodHasPendingLimit(state, type, period));

  return (
    <Period
      period={period}
      selectedPeriodId={selectedPeriod?.id}
      hasPending={hasPending}
      onClick={(e) => {
        e.preventDefault();
        onChange(period);
      }}
    />
  );
};

LossLimitPeriod.propTypes = {
  period: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default LossLimitPeriod;
