import { createSelector } from 'reselect';
import {
  availableForLicence,
  mandatoryForLicence,
  NO_LIMIT_PERIOD,
} from 'CashierCommon/helpers';
import { depositDomain } from './deposit';

const getValue = (_, value) => value;
const getValues = (_, ...values) => values;

export const showLossLimitsModal = createSelector(
  depositDomain,
  (substate) => substate.showModal.lossLimits,
);

const getLossLimits = createSelector(
  depositDomain,
  (substate) => substate.lossLimits,
);

export const hasLoadedLossLimitConfigs = createSelector(
  depositDomain,
  (substate) => substate.lossLimitConfigs !== null,
);

export const hasLoadedLossLimits = createSelector(
  getLossLimits,
  (substate) => substate !== null,
);

const getConfigs = createSelector(
  depositDomain,
  (substate) => (substate.lossLimitConfigs || []).filter(availableForLicence),
);

export const getPeriods = createSelector(
  getLossLimits,
  (substate) => substate?.periods || [],
);

export const getTypes = createSelector(
  [getLossLimits, getConfigs],
  (substate, configs) => (configs || []).reduce((types, config) => {
    const type = substate?.types.find(({ id }) => id === config.typeId);

    return [...types, type];
  }, []).filter(Boolean),
);

export const getLimits = createSelector(
  getLossLimits,
  (substate) => substate?.limits || [],
);

const getDefaultConfig = createSelector(
  getConfigs,
  (typeConfigs) => typeConfigs[0] || {},
);

export const getConfigForType = createSelector(
  [getConfigs, getValue],
  (configs, type) => configs.find((config) => config.typeId === type?.id) || {},
);

export const getPeriodsForType = createSelector(
  [getPeriods, getConfigForType],
  (periods, config) => {
    const periodsForType = periods.filter(({ typeId }) => typeId === config.typeId);
    return config.noLimitAllowed ? [...periodsForType, NO_LIMIT_PERIOD] : periodsForType;
  },
);

const getLimitsForTypeAndPeriod = createSelector(
  [getLimits, getValues],
  (limits, [type, selectedPeriod]) => limits.filter(
    ({ typeId, period, amount }) => typeId === type?.id && (
      period === selectedPeriod?.name || (period === null && amount === null)
      // Matches selected period or no limit
    ),
  ),
);

export const getActiveLimitForTypeAndPeriod = createSelector(
  getLimitsForTypeAndPeriod,
  (limits) => limits.find(({ active }) => active),
);

export const getPendingLimitForTypeAndPeriod = createSelector(
  getLimitsForTypeAndPeriod,
  (limits) => limits.find(({ pending }) => pending),
);

export const periodHasPendingLimit = createSelector(
  [getLimits, getValues],
  (limits, [type, selectedPeriod]) => !!limits.filter(
    ({ typeId, period }) => typeId === type?.id && (
      period === (selectedPeriod?.name === 'no_limit' ? null : selectedPeriod?.name)
      // Matches only selected period
    ),
  ).find(({ pending }) => pending),
);

export const showLossLimitsHeader = createSelector(
  getConfigs,
  (typeConfigs) => !!typeConfigs.length,
);

export const isLimitMandatory = createSelector(
  getConfigs,
  (typeConfigs) => typeConfigs.some(mandatoryForLicence),
);

export const getDefaultPeriod = createSelector(
  [getConfigForType, getPeriodsForType],
  (config, periods) => periods.find((period) => period.name === config.defaultPeriod),
);

export const getDefaultType = createSelector(
  [getDefaultConfig, getTypes],
  (config, types) => types.find((type) => type.id === config.typeId),
);
