import { Button } from 'bv-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Period = ({
  period, hasPending, selectedPeriodId, onClick,
}) => {
  const buttonClassNames = classnames('bvs-button-chip is-small', {
    active: period.id === selectedPeriodId,
    pending: hasPending,
  });

  return (
    <li className="edit-limit__option">
      <Button
        className={buttonClassNames}
        label={period.label}
        onClick={onClick}
        noClass
      />
    </li>
  );
};

Period.propTypes = {
  period: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  hasPending: PropTypes.bool,
  selectedPeriodId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

Period.defaultProps = {
  selectedPeriodId: null,
  hasPending: false,
};

export default Period;
