import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const EditLimitHeader = ({
  headerTitle,
  headerBody,
  onClick,
}) => (
  <div className="edit-limit-header">
    <div className="edit-limit-header__settings">
      <div className="edit-limit-header__details">
        <p className="edit-limit-header__details-title">{headerTitle}</p>
        {headerBody}
      </div>
      <div className="edit-limit-header__edit">
        <Button
          type="button"
          label={t('edit')}
          primary
          onClick={onClick}
        />
      </div>
    </div>
  </div>
);

EditLimitHeader.propTypes = {
  headerTitle: PropTypes.node.isRequired,
  headerBody: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EditLimitHeader;
