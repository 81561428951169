import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';

import { fetchWallets as fetchWalletsAction } from 'CashierActions/ducks/deposit_thunks';
import { selectWallets, selectWalletId } from 'CashierActions/selectors/deposit';
import { setSelectedWalletId as setSelectedWalletIdAction } from 'CashierActions/ducks/deposit_store';

const WalletSelect = ({
  wallets,
  selectedWalletId,
  fetchWallets,
  setSelectedWalletId,
}) => {
  const changeWallet = (event) => setSelectedWalletId(event.target.value);

  useEffect(() => {
    fetchWallets();
  }, []);

  return wallets.length > 1 && (
    <div className="bvs-msg-box">
      <select onChange={changeWallet} value={selectedWalletId}>
        {wallets.map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </select>
    </div>
  );
};

WalletSelect.propTypes = {
  fetchWallets: PropTypes.func.isRequired,
  wallets: PropTypes.instanceOf(Array).isRequired,
  setSelectedWalletId: PropTypes.func.isRequired,
  selectedWalletId: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  wallets: selectWallets,
  selectedWalletId: selectWalletId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWallets: compose(dispatch, fetchWalletsAction),
  setSelectedWalletId: compose(dispatch, setSelectedWalletIdAction),
});

export { WalletSelect as WalletSelectTest };

export default connect(mapStateToProps, mapDispatchToProps)(WalletSelect);
