import { toCurrency } from 'bv-helpers/number';
import { t, locale } from 'bv-i18n';

export const NO_LIMIT_PERIOD = 'no_limit';
export const NO_LIMIT_PERIOD_ID = -1;

export const allFieldsSelected = (amount, periodId) => (
  (amount && periodId) || periodId === NO_LIMIT_PERIOD_ID
);

export const formatAmount = (amount, period) => {
  if (Number.isInteger(amount) && period) {
    return `${toCurrency(amount, { decimalPlaces: 0 })} ${period}`;
  } if (Number.isInteger(amount) || amount === 0) {
    return toCurrency(amount, { decimalPlaces: 0 });
  } if (period) {
    return `${period}`;
  }

  return amount;
};

export const formatPendingLimit = ({ amount, period, effectiveOn }) => {
  const effectiveOnDate = new Date(effectiveOn);
  const date = effectiveOnDate.toLocaleDateString(locale());
  const time = effectiveOnDate.toLocaleTimeString(locale(), {
    hour: '2-digit',
    minute: '2-digit',
  });

  return t('account_form.deposit_limit_pending', {
    amount: amount ? formatAmount(amount) : t('account_form.period.no_limit'),
    frequency: t(`account_form.period.${period === NO_LIMIT_PERIOD ? 'daily' : period}`),
    pending_date: `${date} ${t('account_form.deposit_limit_pending_at')} ${time}`,
  });
};
