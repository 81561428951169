import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';

import { selectPendingWithDraws } from 'CashierActions/selectors/withdraw';
import { fetchPendingWithdraws as fetchPendingWithdrawsAction } from 'CashierActions/ducks/withdraw_thunks';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import EmptyList from './empty_withdraw_list';
import WithdrawItem from './withdraw_item';

const PendingWithdrawsList = ({ withdraws, fetchPendingWithdraws }) => {
  useEffect(() => {
    fetchPendingWithdraws();
  }, []);

  if (!withdraws.length) {
    return <EmptyList />;
  }

  return (
    <>
      {withdraws.map((item) => <WithdrawItem key={item.transaction_id} withdraw={item} />)}
    </>
  );
};

PendingWithdrawsList.propTypes = {
  withdraws: PropTypes.instanceOf(Array).isRequired,
  fetchPendingWithdraws: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  withdraws: selectPendingWithDraws,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPendingWithdraws: compose(dispatch, fetchPendingWithdrawsAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withCashierModal({ tKey: 'withdraw' })(PendingWithdrawsList),
);
