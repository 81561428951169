import { createApplePaySession, processApplePay } from 'CashierCommon/api/deposit_api';
import { paymentRequest } from './method';

const handlePay = async (value, sessionToken, methodName, setUrl, setResult) => {
  try {
    const request = paymentRequest({ value });

    request.onmerchantvalidation = (event) => {
      createApplePaySession({ validationURL: event.validationURL })
        .then((response) => {
          event.complete(response);
        })
        .catch(() => {
          event.complete('fail');
          setResult({ deposit: 'true' });
        });
    };

    request
      .show()
      .then((paymentResponse) => {
        processApplePay({
          sessionToken, paymentToken: paymentResponse.details.token, methodName,
        })
          .then(({ success, redirectUrl }) => {
            paymentResponse.complete(success ? 'success' : 'fail');
            if (redirectUrl) {
              setUrl(redirectUrl);
            } else {
              setResult({ deposit: 'true' });
            }
          })
          .catch(() => {
            paymentResponse.complete('fail');
            setResult({ deposit: 'true' });
          });
      })
      .catch(() => {});
  } catch (e) {
    setResult({ deposit: 'true' });
  }
};

export default handlePay;
