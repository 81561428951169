import PropTypes from 'prop-types';
import { periodType } from 'LossLimits/types';
import LossLimitPeriod from './loss_limit_period';

const PeriodSelector = ({ periods }) => {
  if (periods.length <= 1) return null;

  return (
    <ul className="edit-limit__options">
      {periods.map((period) => (
        <LossLimitPeriod
          key={period.id}
          period={period}
        />
      ))}
    </ul>
  );
};

PeriodSelector.propTypes = {
  periods: PropTypes.arrayOf(periodType).isRequired,
};

export default PeriodSelector;
