import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { useEffect, useState } from 'react';
import { formatAmount } from 'DepositLimits/helpers';
import { CurrencyInput, Spinner } from 'bv-components';
import QuickAmountButtons from '../components/quick_amount_buttons';
import { findFeMethod } from './methods';

const Form = ({
  name: methodName,
  sessionToken,
  minAmount,
  maxAmount,
  setLoaded,
  setResult,
  quickAmountButtons,
  iframeRef,
  amount: lastDeposit,
  integrationAdditionalInfo,
  isQuickDeposit,
}) => {
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [changeAmount, setChangeAmount] = useState(lastDeposit);
  const [url, setUrl] = useState('');
  const { handlePay, PayButton } = findFeMethod(methodName);
  useEffect(setLoaded, [sessionToken]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (input.amount && !input.error) {
      handlePay(
        input.amount,
        sessionToken,
        methodName,
        setUrl,
        setResult,
        setLoading,
        integrationAdditionalInfo,
      );
    } else {
      setChangeAmount(-1);
    }
  };

  const addQuickAmount = (quickAmount) => {
    setChangeAmount(Number(input.amount || 0) + Number(quickAmount));
  };

  const formClassname = classnames(
    'fe-deposit-form__form',
    { 'fe-deposit-form__form--quick-deposit': isQuickDeposit },
  );

  const msg = t('payments.deposit.min_max', { min: formatAmount(minAmount), max: formatAmount(maxAmount) });

  const validate = {
    defaultMsg: msg,
    min: { val: minAmount, msg },
    max: { val: maxAmount, msg },
  };

  return (
    <form onSubmit={onSubmit} className={formClassname}>
      {loading ? <Spinner /> : (
        <>
          {isQuickDeposit && <p className="fe-deposit-form__title">{t('deposit')}</p>}
          <CurrencyInput
            onAmountChange={(state) => { setChangeAmount(); setInput(state); }}
            changeAmount={changeAmount}
            validate={validate}
            placeholder={t('payments.deposit.amount')}
          />
          {quickAmountButtons && (
          <QuickAmountButtons
            amounts={quickAmountButtons}
            action={addQuickAmount}
          />
          )}
          <PayButton
            onSubmit={onSubmit}
            integrationAdditionalInfo={integrationAdditionalInfo}
          />
        </>
      )}
      <iframe ref={iframeRef} src={url} onLoad={() => setUrl('')} style={{ display: 'none' }} />
    </form>
  );
};

Form.propTypes = {
  name: PropTypes.string.isRequired,
  sessionToken: PropTypes.string.isRequired,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  setLoaded: PropTypes.func.isRequired,
  setResult: PropTypes.func.isRequired,
  quickAmountButtons: PropTypes.string,
  iframeRef: PropTypes.instanceOf(Object),
  amount: PropTypes.number,
  integrationAdditionalInfo: PropTypes.instanceOf(Object).isRequired,
  isQuickDeposit: PropTypes.bool,
};

Form.defaultProps = {
  quickAmountButtons: null,
  iframeRef: null,
  amount: null,
  isQuickDeposit: false,
};

export default Form;
