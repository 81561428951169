import * as applePay from './apple_pay';
import * as googlePay from './google_pay';

const feMethods = { applePay, googlePay };
const { isSecureContext } = window;

export const findFeMethod = (name) => Object.values(feMethods)
  .find((m) => m.methodNames.includes(name));

export const checkBrowserSupport = async ({ name }) => {
  const feDepositMethod = findFeMethod(name);

  if (!feDepositMethod) return true;
  if (!isSecureContext) return false;

  try {
    return await feDepositMethod.isSupported();
  } catch {
    return false;
  }
};

export const filterDepositMethods = async (methods) => Promise
  .allSettled(methods.map(checkBrowserSupport))
  .then((results) => methods.filter((_, index) => results[index]?.value || false));
