export const SET_PAYMENTS_LIST = 'cashierActions/SET_PAYMENTS_LIST';
export const REMOVE_PAYMENT_FROM_LIST = 'cashierActions/REMOVE_PAYMENT_FROM_LIST';
export const TOGGLE_SPINNER = 'cashierActions/TOGLLE_SPINNER';

export const setPaymentsList = (payments) => ({
  type: SET_PAYMENTS_LIST,
  payments,
});

export const removePaymentFromList = (payment) => ({
  type: REMOVE_PAYMENT_FROM_LIST,
  payment,
});

export const toggleSpinner = (show) => ({
  type: TOGGLE_SPINNER,
  show,
});

export const initialState = {
  payments: [],
  showSpinner: false,
  paymentId: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PAYMENTS_LIST:
      return { ...state, payments: action.payments };
    case REMOVE_PAYMENT_FROM_LIST:
      return { ...state, payments: state.payments.filter((item) => item.id !== action.payment.id) };
    case TOGGLE_SPINNER:
      return { ...state, showSpinner: action.show };
    default:
      return state;
  }
};
