import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { Icon, Link } from 'bv-components';
import { currencyPrefix } from 'bv';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { t } from 'bv-i18n';

import { operationTypes } from 'CashierCommon/helpers';
import { selectPendingAmount, selectPendingWithDraws } from 'CashierActions/selectors/withdraw';
import { fetchPendingWithdraws as fetchPendingWithdrawsAction } from 'CashierActions/ducks/withdraw_thunks';

const PendingWithdraws = ({
  fetchPendingWithdraws,
  withdraws,
  amount,
  operation,
}) => {
  useEffect(() => {
    fetchPendingWithdraws();
  }, []);

  if (!withdraws.length) return null;

  return (
    <>
      {operation === 'withdraw'
      && (
      <h3 className="pending-withdraws__title">
        {t('javascript.cashier.withdrawals_to_account_message')}
      </h3>
      )}
      <div
        className={classnames({
          'bvs-msg-box': true,
          'is-left': true,
          'pending-withdraws': true,
        })}
      >
        <Link
          to="/bv_cashier/withdraw/pending"
          className="pending-withdraws__link"
        >
          {t('pending_withdrawals')}
          {operation === 'withdraw'
          && (
          <span
            id="amount-pending"
            className="pending-withdraws__amount"
          >
            (
            {`${currencyPrefix()} ${amount}`}
            )
          </span>
          )}
          <div className="pending-withdraws__container">
            <span
              id="pending-withdrawals-counter"
              className="id-pending-withdrawals-counter pending-withdraws__counter"
            >
              {withdraws.length}
            </span>
            <Icon id="arrow-right" />
          </div>
        </Link>
      </div>
    </>
  );
};

PendingWithdraws.propTypes = {
  amount: PropTypes.number.isRequired,
  withdraws: PropTypes.instanceOf(Array).isRequired,
  fetchPendingWithdraws: PropTypes.func.isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
};

const mapStateToProps = createStructuredSelector({
  amount: selectPendingAmount,
  withdraws: selectPendingWithDraws,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPendingWithdraws: compose(dispatch, fetchPendingWithdrawsAction),
});

export { PendingWithdraws as PendingWithdrawsTest };

export default connect(mapStateToProps, mapDispatchToProps)(PendingWithdraws);
