import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import { deletePaymentMethod } from 'CashierActions/ducks/payment_thunks';
import { deleteConfirm, formMap } from 'CashierCommon/helpers';
import * as paymentItems from './payment_items';

const PaymentItem = ({
  item,
  removeModal,
  deletePayment,
}) => {
  const itemType = formMap[item.paysol_name] || 'DefaultItem';
  const PaymentMethod = paymentItems[itemType];

  const deletePaymentItem = () => {
    removeModal();
    deletePayment(item);
  };

  const showDeleteConfirm = () => {
    deleteConfirm(deletePaymentItem);
  };

  return (
    <PaymentMethod
      item={item}
      showDeleteConfirm={showDeleteConfirm}
    />
  );
};

PaymentItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  deletePayment: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deletePayment: compose(dispatch, deletePaymentMethod),
  removeModal: () => {
    // TODO: This is using ModalHelpers which were created for rendering from Backbone
    // This should not use those helpers and just render using JSX based on the state
    const { reduxActionDispatchers } = window;
    reduxActionDispatchers.modal.removeModal();
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(PaymentItem);
