import { compose, noop } from 'underscore';
import { getBlackBox } from 'bv-services';
import { locale } from 'bv-i18n';
import { originId } from 'bv-helpers/session';

import { DEPOSIT } from 'CashierCommon/helpers';
import {
  createCashierSession as apiCreateCashierSession,
  fetchPaymentSettings as apiFetchPaymentSettings,
  fetchAvailableWallets,
} from 'CashierCommon/api/api';
import {
  fetchTermsPageJson,
  postAcceptAgreement,
} from 'CashierCommon/api/deposit_api';
import {
  setWalletsList,
  setCashierSession,
  setSelectedWalletId,
  setTermsPage,
  setPaymentSettings,
} from './deposit_store';
import { fetchPaymentMethods } from './thunks';
import { selectWalletId, getSelectedPaymentMethod } from '../selectors/deposit';

const { VCSTATE } = window;

export const fetchWallets = () => (dispatch) => (
  fetchAvailableWallets()
    .then((response) => {
      dispatch(setWalletsList(response));
      if (response.length) {
        dispatch(setSelectedWalletId(response[0][1]));
      }
    })
);

export const createCashierSession = (operation) => async (dispatch, getState) => {
  const state = getState();
  const walletId = selectWalletId(state);
  const selectedPaymentMethod = getSelectedPaymentMethod(state);
  if (!walletId || !selectedPaymentMethod || !selectedPaymentMethod.name) {
    return;
  }
  const params = {
    wallet_id: walletId,
    iobb: await getBlackBox(),
    operation,
    origin_id: originId(),
    method: selectedPaymentMethod.name,
    css_url: VCSTATE.CASHIER_CSS_URL,
    callback_url: `${VCSTATE.BASE_URL}/cashier/callback/${operation}/`,
    _method: 'put',
  };
  if (operation === 'deposit') {
    params.processing_payment_url = `${window.location.origin}/bv_cashier/${locale()}/processing_payment?mobile=1`;
  }
  apiCreateCashierSession(params)
    .then(compose(dispatch, setCashierSession));
};

export const fetchDeposits = () => fetchPaymentMethods(DEPOSIT);

export const fetchTermsPage = () => (dispatch) => (
  fetchTermsPageJson()
    .then(compose(dispatch, setTermsPage))
);

export const sendAcceptAgreement = () => (dispatch) => (
  postAcceptAgreement()
    .catch(noop)
    .then(() => {
      dispatch(createCashierSession('deposit'));
    })
);

export const fetchPaymentSettings = () => (dispatch) => (
  apiFetchPaymentSettings()
    .then(compose(dispatch, setPaymentSettings))
);
