import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';
import { formatLimitAmount } from 'CashierCommon/helpers';
import { limitType } from 'LossLimits/types';

const CurrentLimit = ({
  activeLimit,
  pendingLimit,
}) => (
  <p className="edit-limit__current-limit">
    {activeLimit && (
      <span dangerouslySetInnerHTML={{
        __html: sanitize(activeLimit.amount === null
          ? t('account_form.loss_limit_settings.modal.limit_set', {
            period: `<strong>${activeLimit.periodLabel}</strong>`,
          })
          : t('account_form.loss_limit_settings.modal.current_amount', {
            period: activeLimit.periodLabel,
            amount: `<strong>${formatLimitAmount(activeLimit)}</strong>`,
          })),
      }}
      />
    )}
    {pendingLimit && (
      <span dangerouslySetInnerHTML={{
        __html: sanitize(` ${t('account_form.loss_limit_settings.modal.pending_amount', {
          amount: `<strong>${formatLimitAmount(pendingLimit)}</strong>`,
        })}`),
      }}
      />
    )}
  </p>
);

CurrentLimit.propTypes = {
  activeLimit: limitType,
  pendingLimit: limitType,
};

CurrentLimit.defaultProps = {
  activeLimit: null,
  pendingLimit: null,
};

export default CurrentLimit;
