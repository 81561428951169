import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { typeType } from 'LossLimits/types';

const TypeSelector = ({
  types,
  input: { onChange, value },
}) => (
  <ul className="edit-limit__type-options">
    {types.map((type) => (
      <li key={type.id} className="edit-limit__type-option">
        <Button
          className="bvs-button-tab"
          active={type.id === value.id}
          label={type.label}
          onClick={(e) => {
            e.preventDefault();
            onChange(type);
          }}
          noClass
        />
      </li>
    ))}
  </ul>
);

TypeSelector.propTypes = {
  types: PropTypes.arrayOf(typeType).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
};

export default TypeSelector;
