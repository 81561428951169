import PropTypes from 'prop-types';
import { formatAmount } from '../helpers';

const Amounts = ({
  values, selectedAmount, handleChange,
}) => (
  <div className="bvs-form-group">
    <select
      className="bvs-form-control"
      value={selectedAmount}
      onChange={({ target: { value } }) => handleChange(+value)}
    >
      {values.map((value) => (
        <option key={value} value={value}>{formatAmount(value)}</option>
      ))}
    </select>
  </div>
);

Amounts.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAmount: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Amounts;
