import { t } from 'bv-i18n';
import { fetchPayments as apiFetchPayments, deletePaymentItem } from 'CashierCommon/api/payment_api';
import { showFetchError, showSuccessMessage } from 'CashierCommon/helpers';
import { setPaymentsList, toggleSpinner, removePaymentFromList } from './payment_store';

const successAction = (dispatch, action) => {
  dispatch(toggleSpinner(false));
  dispatch(action);
};

const showErrorsMessage = (dispatch) => {
  dispatch(toggleSpinner(false));
  showFetchError();
};

export const fetchPayments = () => (dispatch, getState) => {
  const { payments } = getState().paymentStore;
  if (payments && payments.length) {
    return;
  }
  dispatch(toggleSpinner(true));
  apiFetchPayments()
    .then((response) => successAction(dispatch, setPaymentsList(response)));
};

export const deletePaymentMethod = (paymentMethodItem) => (dispatch) => {
  dispatch(toggleSpinner(true));
  deletePaymentItem(paymentMethodItem)
    .then((response) => {
      if (response.success) {
        successAction(dispatch, removePaymentFromList(paymentMethodItem));
        showSuccessMessage(t('payment_method_removed'));
        return;
      }
      showErrorsMessage(dispatch);
    });
};
