import { useState, useEffect } from 'react';
import { sanitize } from 'dompurify';
import { accountData } from 'bv';
import { t } from 'bv-i18n';

const SaferGamblingMessage = () => {
  const [enabled, setEnabled] = useState(false);

  const linkClass = 'bvs-link';
  const message = t('account_form.deposit_limit_settings.safer_gambling_message', {
    safer_gambling_portal_link: `<a href="safer_gambling/portal" class="${linkClass}">${t('account_form.deposit_limit_settings.safer_gambling_portal')}</a>`,
    phone_number: `<a href="tel:0808 8020 133" class="${linkClass}">0808 8020 133</a>`,
    gamble_aware_link: `<a href="https://www.gambleaware.org/" target="_blank" class="${linkClass}">gambleaware.org</a>`,
  });

  useEffect(() => {
    accountData().then((account) => {
      setEnabled(account.country_code.toLowerCase() === 'gb');
    });
  }, []);

  if (!enabled) {
    return null;
  }

  return <p dangerouslySetInnerHTML={{ __html: sanitize(message) }} className="edit-limit-header__message" />;
};

export default SaferGamblingMessage;
