import paymentStore from './ducks/payment_store';
import depositStore from './ducks/deposit_store';
import withdrawStore from './ducks/withdraw_store';
import App from './app';

const { addReducers } = window.reduxState;

addReducers({
  paymentStore,
  depositStore,
  withdrawStore,
});

export default App;
