import { defaultAssetPath } from 'bv';
import PropTypes from 'prop-types';

const SelectedPaymentMethod = ({ name, logo_url: logoUrl }) => logoUrl && (
  <div className="payment-menu__single">
    <img src={defaultAssetPath(logoUrl)} alt={name} />
  </div>
);

SelectedPaymentMethod.propTypes = {
  logo_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SelectedPaymentMethod;
