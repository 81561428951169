import PropTypes from 'prop-types';

import { hidePan } from 'CashierCommon/helpers';
import DefaultItem from './default_item';

const Neteller = (props) => {
  const { item } = props;

  return (
    <DefaultItem name={hidePan(item.attributes.pan)} {...props} />
  );
};

Neteller.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default Neteller;
