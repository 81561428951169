import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FeatureGuardComponent } from 'bv-components';
import { t } from 'bv-i18n';
import { openModal, closeModal } from 'CashierActions/ducks/deposit_store';
import {
  setDepositLimit,
} from 'CashierActions/ducks/deposit_limit_thunks';
import { limitModals } from 'CashierCommon/helpers';
import {
  getDepositLimitIsUpdating,
  showDepositLimitModal,
  showDepositLimitHeader,
  isLimitMandatory,
} from 'CashierActions/selectors/deposit_limit';
import withDepositLimits from 'DepositLimits/with_deposit_limits';
import DepositLimitView from './deposit_limit_view';
import EditLimitText from '../edit_limit_text';
import SaferGamblingMessage from '../../../safer_gambling_message';

const DepositLimitContainer = ({
  hasLoaded,
  periods,
  amounts,
  selectedPeriodId,
  selectedAmount,
  handlePeriodChange,
  handleAmountChange,
}) => {
  const dispatch = useDispatch();
  const depositLimitIsUpdating = useSelector(getDepositLimitIsUpdating);
  const showModal = useSelector(showDepositLimitModal);
  const showHeader = useSelector(showDepositLimitHeader);
  const isMandatory = useSelector(isLimitMandatory);

  const openDepositLimitModal = () => {
    dispatch(openModal(limitModals.DEPOSIT_LIMIT));
  };

  const closeDepositLimitModal = () => {
    dispatch(closeModal(limitModals.DEPOSIT_LIMIT));
  };

  const submitDepositLimit = () => {
    dispatch(setDepositLimit(selectedAmount, selectedPeriodId));
  };

  useEffect(() => {
    if (hasLoaded && isMandatory) openDepositLimitModal();
  }, [hasLoaded]);

  if (!showHeader) return null;

  return (
    <>
      <EditLimitText
        limitType={t('cashier.limits.type.rdl')}
        link="/safer_gambling/portal/deposit_limits"
      />
      <FeatureGuardComponent feature="deposit_safer_gambling_message">
        <SaferGamblingMessage />
      </FeatureGuardComponent>
      {showModal && (
        <DepositLimitView
          selectedPeriodId={selectedPeriodId}
          selectedAmount={selectedAmount}
          handlePeriodChange={handlePeriodChange}
          handleAmountChange={handleAmountChange}
          submitDepositLimit={submitDepositLimit}
          onClose={closeDepositLimitModal}
          iconClosable={!isMandatory}
          periods={periods}
          amounts={amounts}
          depositLimitIsUpdating={depositLimitIsUpdating}
        />
      )}
    </>
  );
};

DepositLimitContainer.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  periods: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedPeriodId: PropTypes.number,
  selectedAmount: PropTypes.number,
  handlePeriodChange: PropTypes.func.isRequired,
  handleAmountChange: PropTypes.func.isRequired,
};

DepositLimitContainer.defaultProps = {
  selectedPeriodId: null,
  selectedAmount: null,
};

export default withDepositLimits(DepositLimitContainer);
