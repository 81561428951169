import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActiveLimit,
  getAmounts,
  getCurrentLimits,
  getCurrentPeriodId,
  getDefaultPeriodId,
  getHasLoaded,
  getLimitOptions,
  getPendingLimit,
  getPeriods,
} from './selectors';
import reducer, { loadCurrentLimits, loadLimitOptions } from './duck';
import { NO_LIMIT_PERIOD, NO_LIMIT_PERIOD_ID } from './helpers';

const { addReducers } = window.reduxState;

addReducers({
  depositLimits: reducer,
});

const wrapper = (WrappedComponent) => {
  const WithDepositLimits = (props) => {
    const dispatch = useDispatch();
    const currentLimits = useSelector(getCurrentLimits);
    const limitOptions = useSelector(getLimitOptions);
    const hasLoaded = useSelector(getHasLoaded);
    const activeLimit = useSelector(getActiveLimit);
    const pendingLimit = useSelector(getPendingLimit);
    const amounts = useSelector(getAmounts);
    const periods = useSelector(getPeriods);
    const defaultPeriodId = useSelector(getDefaultPeriodId);
    const currentPeriodId = useSelector(getCurrentPeriodId);

    const [selectedPeriodId, setSelectedPeriodId] = useState(defaultPeriodId);
    const [selectedAmount, setSelectedAmount] = useState(null);

    const amountOptions = useMemo(() => (
      selectedPeriodId === NO_LIMIT_PERIOD_ID ? [null] : [null, ...amounts]
    ), [selectedPeriodId, amounts]);

    const handlePeriodChange = (periodId) => {
      if ([periodId, selectedPeriodId].includes(NO_LIMIT_PERIOD_ID)) {
        setSelectedAmount('');
      }

      setSelectedPeriodId(periodId);
    };

    useEffect(() => {
      if (!limitOptions) dispatch(loadLimitOptions());
    }, [limitOptions]);

    useEffect(() => {
      if (limitOptions && !currentLimits) dispatch(loadCurrentLimits());
    }, [limitOptions, currentLimits]);

    useEffect(() => {
      if (hasLoaded) {
        if (activeLimit?.period === NO_LIMIT_PERIOD) {
          setSelectedPeriodId(NO_LIMIT_PERIOD_ID);
        } else if (activeLimit) {
          setSelectedPeriodId(currentPeriodId);
          setSelectedAmount(activeLimit.amount);
        } else if (defaultPeriodId) {
          setSelectedPeriodId(defaultPeriodId);
        }
      }
    }, [hasLoaded, activeLimit, currentPeriodId, defaultPeriodId]);

    return (
      <WrappedComponent
        hasLoaded={hasLoaded}
        activeLimit={activeLimit}
        pendingLimit={pendingLimit}
        amounts={amountOptions}
        periods={periods}
        selectedPeriodId={selectedPeriodId}
        selectedAmount={selectedAmount}
        handlePeriodChange={handlePeriodChange}
        handleAmountChange={setSelectedAmount}
        {...props}
      />
    );
  };

  WithDepositLimits.displayName = 'withDepositLimits';

  return WithDepositLimits;
};

export default wrapper;
