import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import withLazyLoad from 'bv-lazy-load';

const FundsProtectionModal = ({
  onReadMoreLinkClick,
  onAccept,
  onDecline,
}) => {
  const { VCSTATE } = window;
  const LazyFundsProtectionModal = withLazyLoad()('fundsProtectionModal');

  return (
    <LazyFundsProtectionModal
      url={(VCSTATE.CUSTOMER_CONSENT_URLS || {}).insolvency_protection}
      onReadMoreLinkClick={onReadMoreLinkClick}
      onPrimaryClick={onAccept}
      onSecondaryClick={onDecline}
      primaryButtonText={t('javascript.accept')}
      secondaryButtonText={t('javascript.customer_consent.do_not_accept')}
    />
  );
};

FundsProtectionModal.propTypes = {
  onReadMoreLinkClick: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default FundsProtectionModal;
