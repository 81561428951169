import { getCookie } from 'bv';
import { getCurrencyCode } from 'bv-helpers/price';
import { processGooglePay } from 'CashierCommon/api/deposit_api';
import { paymentsClient, methodData } from './method';

const countryCode = getCookie('country_code');
const currencyCode = getCurrencyCode();

const handlePay = async (
  value, sessionToken, methodName, setUrl, setResult, setLoading, integrationAdditionalInfo,
) => {
  const paymentDataRequest = {
    ...methodData(integrationAdditionalInfo),
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPrice: `${value}`,
      currencyCode,
      countryCode,
    },
  };

  const client = await paymentsClient();

  client.loadPaymentData(paymentDataRequest)
    .then((paymentData) => {
      setLoading(true);
      processGooglePay({
        sessionToken,
        paymentMethodData: paymentData.paymentMethodData,
        methodName,
        amount: `${value}`,
      })
        .then(({ redirectUrl }) => {
          setLoading(false);
          if (redirectUrl) {
            setUrl(redirectUrl);
          } else {
            setResult({ deposit: 'true' });
          }
        })
        .catch(() => {
          setLoading(false);
          setResult({ deposit: 'true' });
        });
    })
    .catch((err) => {
      setLoading(false);
      if (err?.statusCode === 'CANCELED') return;
      setResult({ deposit: 'true' });
    });
};

export default handlePay;
