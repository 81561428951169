import { createSelector } from 'reselect';
import { compose, property } from 'underscore';

export const depositDomain = (state) => state.depositStore;

const getOperation = (state, { operation }) => operation;

export const selectPaymentMethods = createSelector(
  depositDomain,
  (substate) => substate.paymentMethods,
);

export const getSelectedPaymentMethodId = createSelector(
  depositDomain,
  (substate) => parseInt(substate.selectedPaymentMethodId, 10),
);

export const getSelectedPaymentMethod = createSelector(
  [selectPaymentMethods, getSelectedPaymentMethodId],
  (paymentMethods, id) => paymentMethods.find((item) => item.id === id),
);

export const selectWalletId = createSelector(
  depositDomain,
  (substate) => substate.selectedWalletId,
);

export const selectWallets = createSelector(
  depositDomain,
  (substate) => substate.wallets,
);

export const selectSession = createSelector(
  depositDomain,
  (substate) => substate.session,
);

export const selectBankList = createSelector(
  depositDomain,
  (substate) => substate.bankList,
);

export const selectTermsPageDetails = createSelector(
  depositDomain,
  (substate) => substate.termsPage,
);

export const getPaymentMessage = createSelector(
  depositDomain,
  getOperation,
  (substate, operation) => substate.paymentMessages.find((message) => (
    message.transaction_type.toLowerCase() === operation.toLowerCase()
  )),
);

export const selectLoading = compose(property('loading'), depositDomain);

export const selectResult = compose(property('result'), depositDomain);
