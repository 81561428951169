import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';

import { Spinner } from 'bv-components';
import { fetchPayments as fetchPaymentsAction } from 'CashierActions/ducks/payment_thunks';
import { getPayments, getSpinner } from 'CashierActions/selectors/payments';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import EmptyList from './empty_list';
import PaymentItem from './payment_item';

const Payments = ({ payments, showSpinner, fetchPayments }) => {
  useEffect(() => {
    fetchPayments();
  }, []);

  if (showSpinner) {
    return <Spinner />;
  }

  if (!payments.length) {
    return <EmptyList />;
  }

  return (
    <div className="manage-payments-wrapper">
      <ul className="bvs-card cashier-payments">
        {payments.map((item) => <PaymentItem item={item} key={item.id} />)}
      </ul>
    </div>
  );
};

Payments.defaultProps = {
  payments: [],
};

Payments.propTypes = {
  payments: PropTypes.instanceOf(Array),
  fetchPayments: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  payments: getPayments,
  showSpinner: getSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayments: compose(dispatch, fetchPaymentsAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withCashierModal({ tKey: 'manage_payments', className: 'manage-payments-modal' })(Payments),
);
