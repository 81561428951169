import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';

const EditLimitText = ({
  limitType,
  link,
}) => (
  <p
    className="edit-limit-header__message"
    dangerouslySetInnerHTML={{
      __html: sanitize(
        t('cashier.limits.set', {
          type: `<a href="${link}" class="bvs-link">${limitType}</a>`,
        }),
      ),
    }}
  />
);

EditLimitText.propTypes = {
  limitType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default EditLimitText;
