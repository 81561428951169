import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const EmptyList = () => (
  <div className="bvs-msg-box">
    <Button type="submit" label={t('no_pending_withdrawals')} primary />
  </div>
);

export default EmptyList;
