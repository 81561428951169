import PropTypes from 'prop-types';

import DefaultItem from './default_item';

const CreditDebitCard = (props) => {
  const { item } = props;

  return (
    <DefaultItem
      {...props}
      name={`${item.attributes.cardType} ****${item.attributes.cardLastDigits}`}
    />
  );
};

CreditDebitCard.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default CreditDebitCard;
