import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import {
  LOSS_LIMIT_FORM as FORM,
  NO_LIMIT_PERIOD_ID,
} from 'CashierCommon/helpers';
import {
  getDefaultPeriod,
  getPeriodsForType,
  getConfigForType,
  getActiveLimitForTypeAndPeriod,
  getPendingLimitForTypeAndPeriod,
} from 'CashierActions/selectors/loss_limit';
import Amount from 'StakeLimits/components/fields/amount';
import { amountValidations } from 'StakeLimits/validations';
import { typeType } from 'LossLimits/types';
import PeriodSelector from './period_selector';
import TypeSelector from './type_selector';
import CurrentLimit from './current_limit';

const LossLimitsView = ({
  types,
  defaultType,
}) => {
  const {
    submit,
    change,
    resetFieldState,
  } = useForm();
  const {
    submitting,
    invalid,
    values,
  } = useFormState();

  const selectedType = values[FORM.fields.type];
  const selectedPeriod = values[FORM.fields.period];
  const amount = values[FORM.fields.amount];

  const config = useSelector((state) => getConfigForType(state, selectedType));
  const periods = useSelector((state) => getPeriodsForType(state, selectedType));
  const defaultPeriod = useSelector((state) => getDefaultPeriod(state, selectedType));
  const activeLimit = useSelector(
    (state) => getActiveLimitForTypeAndPeriod(state, selectedType, selectedPeriod),
  );
  const pendingLimit = useSelector(
    (state) => getPendingLimitForTypeAndPeriod(state, selectedType, selectedPeriod),
  );

  const canSubmit = useMemo(
    () => !submitting && ((amount && !invalid) || selectedPeriod?.id === NO_LIMIT_PERIOD_ID),
    [amount, submitting, invalid, selectedPeriod],
  );

  useEffect(() => {
    if (defaultType) change(FORM.fields.type, defaultType);
  }, []);

  useEffect(() => {
    if (selectedType) change(FORM.fields.config, config);
  }, [selectedType]);

  useEffect(() => {
    if (defaultPeriod) change(FORM.fields.period, defaultPeriod);
  }, [defaultPeriod]);

  useEffect(() => {
    if (selectedPeriod?.id === NO_LIMIT_PERIOD_ID) {
      resetFieldState(FORM.fields.amount);
    }
  }, [selectedPeriod]);

  return (
    <form onSubmit={(event) => { event.preventDefault(); submit(); }}>
      <div className="edit-limit__note">
        <p>{t('account_form.loss_limit_settings.modal.title')}</p>
      </div>
      <div className="edit-limit">
        <Field
          name={FORM.fields.type}
          component={TypeSelector}
          types={types}
        />
        <Field
          name={FORM.fields.period}
          component={PeriodSelector}
          periods={periods}
        />
        <CurrentLimit
          activeLimit={activeLimit}
          pendingLimit={pendingLimit}
        />
        <div className="account-form__field">
          <Field
            id="amount"
            name={FORM.fields.amount}
            component={Amount}
            validate={amountValidations}
            periodIsNotSelected={!selectedPeriod}
            disabled={selectedPeriod?.id === NO_LIMIT_PERIOD_ID}
          />
        </div>
        <div className="bvs-button-group">
          <Button
            className="edit-limit__btn--submit"
            disabled={!canSubmit}
            primary
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

LossLimitsView.propTypes = {
  defaultType: typeType.isRequired,
  types: PropTypes.arrayOf(typeType).isRequired,
};

export default LossLimitsView;
