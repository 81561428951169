import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { setFragment } from 'bv-helpers/location';
import { redirectToVerificationZone } from 'global-config';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { Spinner } from 'bv-components';
import { setCustomerConsentMessages } from 'session-action-types';
import { operationTypes } from 'CashierCommon/helpers';
import iframeMessageReceiver from 'CashierCommon/hooks/iframe_message_receiver';
import {
  selectSession,
  selectWalletId,
  getSelectedPaymentMethod,
  selectLoading,
  selectResult,
} from 'CashierActions/selectors/deposit';
import {
  setPaymentMethods as setPaymentMethodsThunk,
  setLoading,
  setResult,
} from 'CashierActions/ducks/deposit_store';
import { createCashierSession as createCashierSessionThunk } from 'CashierActions/ducks/deposit_thunks';
import { fetchPendingWithdraws as fetchPendingWithdrawsThunk } from 'CashierActions/ducks/withdraw_thunks';
import { fetchPayments as fetchPaymentsThunk } from 'CashierActions/ducks/payment_thunks';
import { useCashierForm } from 'SharedComponents/cashier/hooks';
import { findFeMethod } from 'SharedComponents/cashier/fe_deposit';
import ResultMessage from './result_message';
import FeDepositLoader from './fe_deposit_loader';

const CashierForm = ({
  operation,
  loading,
  session,
  result,
  setPaymentResult,
  selectedWalletId,
  selectedPaymentMethod,
  setLoadingPaymentMethod,
  setPaymentMethods,
  createCashierSession,
  fetchDepositLimit,
  fetchPendingWithdraws,
  fetchPayments,
  loadCustomerConsentMessages,
}) => {
  const { form, canLoadForm } = useCashierForm(session, operation, setLoadingPaymentMethod);

  useEffect(iframeMessageReceiver({
    form,
    operation,
    createCashierSession,
    fetchPendingWithdraws,
    fetchDepositLimit,
    fetchPayments,
    setPaymentMethods,
    setPaymentResult,
  }), []);

  useEffect(() => {
    if (selectedWalletId && selectedPaymentMethod && selectedPaymentMethod.name) {
      createCashierSession(operation);
    }
  }, [selectedWalletId, selectedPaymentMethod]);

  useEffect(() => {
    if (!session?.success && session?.error_code === 'PCC-508') {
      loadCustomerConsentMessages();
    } else if (redirectToVerificationZone && session?.error_code === 'PCC-509') {
      setFragment('/account/verification_zone');
    }
  }, [session]);

  const wrapperClassName = classnames(
    'bvs-msg-box deposit-form',
    { hidden: loading || result },
  );

  const feDepositMethod = operation === 'deposit' && findFeMethod(selectedPaymentMethod.name);

  if (redirectToVerificationZone && session?.error_code === 'PCC-509') return null;

  return (
    <>
      {loading && selectedPaymentMethod && !result && <Spinner />}
      {session?.success && canLoadForm && (
        <div className={wrapperClassName}>
          {feDepositMethod
            ? (
              <FeDepositLoader
                name={selectedPaymentMethod.name}
                sessionToken={session?.parameters?.sessionToken}
                minAmount={selectedPaymentMethod.min_limit}
                maxAmount={selectedPaymentMethod.max_limit}
                setResult={setPaymentResult}
                setLoaded={() => setLoadingPaymentMethod(false)}
                integrationAdditionalInfo={session?.integrationAdditionalInfo || {}}
              />
            ) : (
              <>
                <form
                  action={session.url}
                  method="post"
                  target="cashier-frame"
                  ref={form}
                >
                  {Object.entries(session.parameters).map(([name, value]) => (
                    <input type="hidden" name={name} value={value} key={name} />
                  ))}
                </form>
                <iframe
                  className={`cashier-frame cashier_${session.method}`}
                  name="cashier-frame"
                  frameBorder={0}
                  scrolling="auto"
                  seamless
                  onLoad={() => setLoadingPaymentMethod(false)}
                />
              </>
            )}
        </div>
      )}
      {result && <ResultMessage {...result} setResult={setPaymentResult} />}
    </>
  );
};

CashierForm.defaultProps = {
  selectedPaymentMethod: {},
  result: null,
};

CashierForm.propTypes = {
  session: PropTypes.instanceOf(Object).isRequired,
  selectedWalletId: PropTypes.number.isRequired,
  selectedPaymentMethod: PropTypes.instanceOf(Object),
  createCashierSession: PropTypes.func.isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  setLoadingPaymentMethod: PropTypes.func.isRequired,
  setPaymentMethods: PropTypes.func.isRequired,
  fetchDepositLimit: PropTypes.func.isRequired,
  fetchPendingWithdraws: PropTypes.func.isRequired,
  fetchPayments: PropTypes.func.isRequired,
  setPaymentResult: PropTypes.func.isRequired,
  loadCustomerConsentMessages: PropTypes.func.isRequired,
  result: PropTypes.instanceOf(Object),
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  result: selectResult,
  session: selectSession,
  selectedWalletId: selectWalletId,
  selectedPaymentMethod: getSelectedPaymentMethod,
});

const mapStateToDispatch = (dispatch) => ({
  createCashierSession: compose(dispatch, createCashierSessionThunk),
  setPaymentMethods: compose(dispatch, setPaymentMethodsThunk),
  fetchPendingWithdraws: compose(dispatch, fetchPendingWithdrawsThunk),
  fetchPayments: compose(dispatch, fetchPaymentsThunk),
  setLoadingPaymentMethod: compose(dispatch, setLoading),
  setPaymentResult: compose(dispatch, setResult),
  loadCustomerConsentMessages: compose(dispatch, setCustomerConsentMessages),
});

export { CashierForm as CashierFormTest };

export default connect(mapStateToProps, mapStateToDispatch)(CashierForm);
