import { getJSON, deleteJSON } from 'bv-fetch';

export const fetchPayments = () => (
  getJSON('/cashier/payments.json?')
);

export const deletePaymentItem = (item) => (
  deleteJSON(
    `/cashier/payments/${item.id}.json`,
    {
      paysol_name: item.paysol_name,
    },
  )
);
