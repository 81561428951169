import {
  shape, number, string, arrayOf, bool,
} from 'prop-types';

export const stakeLimitPeriodType = shape({
  id: number,
  name: string,
  label: string,
  minValue: number,
  maxValue: number,
});

export const userStakeLimitType = shape({
  id: number,
  remaining: number,
  amount: number,
  period: string,
  periodLabel: string,
  type: string,
  typeId: number,
  typeLabel: string,
  expirationDate: string,
  active: bool,
  pending: bool,
  dueTime: number,
  activeFromDate: string,
});

export const stakeLimitTypeType = shape({
  id: number,
  name: string,
  label: string,
});

export const userStakeLimitsType = arrayOf(userStakeLimitType);
export const stakeLimitsPeriodsType = arrayOf(stakeLimitPeriodType);
export const stakeLimitsTypesType = arrayOf(stakeLimitTypeType);

export const configDataType = shape({
  limitsRequired: bool,
  coolOffPeriod: string,
  noLimitAllowed: bool,
  typeId: number,
});
