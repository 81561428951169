import PropTypes from 'prop-types';
import classnames from 'classnames';

import { defaultAssetPath } from 'bv';

const CashierMenuItem = ({
  item, onClick, selected, activeRef,
}) => (
  <button
    type="button"
    ref={selected ? activeRef : null}
    className={classnames({
      'bvs-button-card': true,
      'payment-menu__item': true,
      'payment-menu-item--active': selected,
    })}
    onClick={onClick}
    style={{
      backgroundImage: `url('${defaultAssetPath(item.logo_url)}')`,
    }}
  >
    <span className="payment-menu__item-label">{item.name}</span>
  </button>
);

CashierMenuItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  activeRef: PropTypes.func,
};

CashierMenuItem.defaultProps = {
  activeRef: null,
};

export default CashierMenuItem;
