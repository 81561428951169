import { createSelector } from 'reselect';

const depositLimitsDomain = (state) => state.depositLimits;

export const getCurrentLimits = createSelector(
  depositLimitsDomain,
  (substate) => substate.currentLimits,
);

export const getLimitOptions = createSelector(
  depositLimitsDomain,
  (substate) => substate.limitOptions,
);

export const getActiveLimit = createSelector(
  getCurrentLimits,
  (currentLimits) => currentLimits?.active,
);

export const getPendingLimit = createSelector(
  getCurrentLimits,
  (currentLimits) => currentLimits?.pending,
);

export const getPeriods = createSelector(
  getLimitOptions,
  (options) => options?.periods || [],
);

export const getAmounts = createSelector(
  getLimitOptions,
  (options) => options?.amounts || [],
);

export const getHasLoaded = createSelector(
  getCurrentLimits,
  getLimitOptions,
  (currentLimits, limitOptions) => Boolean(currentLimits && limitOptions),
);

export const getDefaultPeriodId = createSelector(
  getLimitOptions,
  getPeriods,
  (options, periods) => (
    periods.find((period) => period.name === options?.defaultPeriod)?.id || null
  ),
);

export const getCurrentPeriodId = createSelector(
  getActiveLimit,
  getPeriods,
  (activeLimit, periods) => (
    periods.find((period) => period.name === activeLimit?.period)?.id || null
  ),
);
