import { t } from 'bv-i18n';
import { formatAmount } from './helpers';

export const amountValidations = (value, allValues) => {
  if (allValues.selectedPeriod?.name === 'no_limit') return undefined;

  if (value === undefined || !value.match(/^\d+$/g)) {
    return t('stake_limits.form.errors.amount.must_be_number');
  }

  if (allValues.selectedPeriod) {
    const { minValue, maxValue, label } = allValues.selectedPeriod;

    if (+value < allValues.selectedPeriod.minValue) {
      return t('stake_limits.form.errors.amount.should_be_greater_than_min', {
        period: label,
        minValue: formatAmount({
          amount: minValue,
          hidePaddedDecimals: true,
        }),
      });
    }

    if (+value > allValues.selectedPeriod.maxValue) {
      return t('stake_limits.form.errors.amount.should_be_lesser_than_max', {
        period: label,
        maxValue: formatAmount({
          amount: maxValue,
          hidePaddedDecimals: true,
        }),
      });
    }
  }

  return undefined;
};
