import { t } from 'bv-i18n';
import { fetchDeposits } from 'CashierCommon/api/deposit_api';
import { fetchWithdrawals } from 'CashierCommon/api/withdraw_api';
import { filterDepositMethods } from 'SharedComponents/cashier/fe_deposit';
import { DEPOSIT, WITHDRAW } from 'CashierCommon/helpers';
import {
  setPaymentMethods,
  setSelectPaymentMethod,
  setLoading,
  setResult,
} from './deposit_store';

const fetchDepositsAndFilter = () => fetchDeposits()
  .then(filterDepositMethods)
  .catch(() => ([]));

const fetchPaymentMethodsFns = {
  [DEPOSIT]: fetchDepositsAndFilter,
  [WITHDRAW]: fetchWithdrawals,
};

const getLastSelectedMethod = (methods, operation) => {
  const id = parseInt(localStorage.getItem(operation), 10);
  return id && methods.find((item) => item.id === id);
};

export const fetchPaymentMethods = (operation) => (dispatch) => {
  fetchPaymentMethodsFns[operation]()
    .then((methods) => {
      if (methods?.length) {
        dispatch(setPaymentMethods(methods));
        const selectedPaymentMethod = getLastSelectedMethod(methods, operation)
          || methods.find((item) => item.name && item.default)
          || methods[0];
        if (selectedPaymentMethod) {
          dispatch(setSelectPaymentMethod(selectedPaymentMethod));
        }
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setResult({
        title: t(`cashier.${operation}`),
        error_message: t('cashier.payments.error_message.access_error'),
      }));
    });
};
