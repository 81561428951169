import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const SecureTransaction = () => (
  <div className="secure-transaction">
    <Icon className="secure-transaction__icon" />
    <div className="secure-transaction__text">{t('quick_deposit.secure_transaction')}</div>
  </div>
);

export default SecureTransaction;
