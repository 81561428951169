import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sanitize } from 'dompurify';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';

import {
  selectSession,
  selectTermsPageDetails,
} from 'CashierActions/selectors/deposit';
import {
  fetchTermsPage as fetchTermsPageAction,
  sendAcceptAgreement,
} from 'CashierActions/ducks/deposit_thunks';
import { clearSession as clearSessionAction } from 'CashierActions/ducks/deposit_store';
import FundsProtectionModal from './funds_protection_modal';

const AcceptTerms = ({
  session,
  termsPage,
  acceptAgreement,
  fetchTermsPage,
  clearSession,
}) => {
  const [showTermsPageModal, setShowTermsPageModal] = useState(false);
  useEffect(() => {
    fetchTermsPage();
  }, []);

  const handleModalClose = () => setShowTermsPageModal(false);

  if (showTermsPageModal && termsPage) {
    return (
      <Modal
        title={termsPage.title}
        info
        iconClosable
        onCloseClick={handleModalClose}
        actions={[
          { label: t('close'), primary: true, onClick: handleModalClose },
        ]}
      >
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(termsPage.body) }}
        />
      </Modal>
    );
  }

  if (session && session.error_code && session.error_code === 'PCC-503') {
    return (
      <FundsProtectionModal
        onReadMoreLinkClick={() => setShowTermsPageModal(true)}
        onAccept={acceptAgreement}
        onDecline={() => {
          clearSession();
          setFragment('bv_cashier/overview');
        }}
      />
    );
  }

  return null;
};

AcceptTerms.propTypes = {
  session: PropTypes.instanceOf(Object).isRequired,
  fetchTermsPage: PropTypes.func.isRequired,
  termsPage: PropTypes.instanceOf(Object).isRequired,
  acceptAgreement: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  session: selectSession,
  termsPage: selectTermsPageDetails,
});

const mapDispatchToProps = {
  fetchTermsPage: fetchTermsPageAction,
  acceptAgreement: sendAcceptAgreement,
  clearSession: clearSessionAction,
};

export { AcceptTerms as AcceptTermsTest };

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
