import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { useState, useEffect } from 'react';
import { paymentsClient, allowedPaymentMethods } from './method';

const { payButtonColor: buttonColor } = bvVar('feDeposit');

const PayButton = ({ onSubmit, integrationAdditionalInfo }) => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    paymentsClient().then(setClient);
  }, []);

  return client ? (
    <div
      className="google-pay-button"
      onClick={onSubmit}
      ref={(el) => {
        if (el && !el.childNodes.length) {
          const button = client.createButton({
            buttonColor,
            buttonType: 'plain',
            buttonRadius: '22',
            allowedPaymentMethods: allowedPaymentMethods(integrationAdditionalInfo),
            onClick: () => {},
          });
          el.appendChild(button);
        }
      }}
    />
  ) : null;
};

PayButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  integrationAdditionalInfo: PropTypes.instanceOf(Object).isRequired,
};

export default PayButton;
