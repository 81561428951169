import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { compose } from 'underscore';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';
import {
  createLossLimit,
  fetchLossLimitConfigs,
  fetchLossLimits,
} from 'CashierActions/ducks/loss_limit_thunks';
import { openModal, closeModal } from 'CashierActions/ducks/deposit_store';
import { LOSS_LIMIT_FORM as FORM } from 'CashierCommon/helpers';
import { selectSession } from 'CashierActions/selectors/deposit';
import {
  getTypes,
  getDefaultType,
  hasLoadedLossLimitConfigs,
  hasLoadedLossLimits,
  isLimitMandatory,
  showLossLimitsModal,
  showLossLimitsHeader,
} from 'CashierActions/selectors/loss_limit';
import { typeType } from 'LossLimits/types';
import EditLimitHeader from '../edit_limit_header';
import LossLimitsView from './loss_limits_view';

const LossLimitsContainer = ({
  session,
  fetchConfigs,
  fetchLimitValues,
  types,
  defaultType,
  showModal,
  showHeader,
  hasLoadedConfigs,
  hasLoadedLimits,
  isMandatory,
  openLossLimitsModal,
  closeLossLimitsModal,
  createLimit,
}) => {
  const isLoaded = useMemo(
    () => session?.success && hasLoadedConfigs && hasLoadedLimits,
    [session, hasLoadedConfigs, hasLoadedLimits],
  );

  useEffect(() => {
    if (!hasLoadedConfigs) fetchConfigs();
  }, []);

  useEffect(() => {
    if (showHeader && !hasLoadedLimits) fetchLimitValues();
  }, [showHeader]);

  useEffect(() => {
    if (isLoaded && isMandatory) openLossLimitsModal();
  }, [isLoaded, showModal]);

  if (!showHeader) return null;

  return (
    <>
      <EditLimitHeader
        headerTitle={t('account_form.loss_limit_settings.title')}
        headerBody={<p>{t('account_form.loss_limit_settings.description')}</p>}
        onClick={openLossLimitsModal}
      />
      {showModal && (
        <Modal
          icon={false}
          actionSheet
          iconClosable={!isMandatory}
          onCloseClick={closeLossLimitsModal}
        >
          <Form onSubmit={createLimit}>
            {() => (
              <LossLimitsView
                types={types}
                defaultType={defaultType}
              />
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

LossLimitsContainer.propTypes = {
  session: PropTypes.instanceOf(Object).isRequired,
  hasLoadedConfigs: PropTypes.bool.isRequired,
  hasLoadedLimits: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(typeType).isRequired,
  defaultType: typeType,
  showHeader: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  openLossLimitsModal: PropTypes.func.isRequired,
  closeLossLimitsModal: PropTypes.func.isRequired,
  fetchConfigs: PropTypes.func.isRequired,
  fetchLimitValues: PropTypes.func.isRequired,
  createLimit: PropTypes.func.isRequired,
};

LossLimitsContainer.defaultProps = {
  defaultType: null,
};

const mapStateToProps = (state) => ({
  session: selectSession(state),
  hasLoadedConfigs: hasLoadedLossLimitConfigs(state),
  hasLoadedLimits: hasLoadedLossLimits(state),
  types: getTypes(state),
  defaultType: getDefaultType(state),
  isMandatory: isLimitMandatory(state),
  showModal: showLossLimitsModal(state),
  showHeader: showLossLimitsHeader(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchLimitValues: compose(dispatch, fetchLossLimits),
  fetchConfigs: compose(dispatch, fetchLossLimitConfigs),
  createLimit: compose(dispatch, createLossLimit),
  openLossLimitsModal: () => dispatch(openModal(FORM.name)),
  closeLossLimitsModal: () => dispatch(closeModal(FORM.name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LossLimitsContainer);
