import { v as bvVar, getCookie, requireScript } from 'bv';

let promise;
const TIMEOUT = 6000;

const countryCode = getCookie('country_code');
const { debitOnlyCountries } = bvVar('feDeposit');
const { environment } = bvVar('googlePay');

const timeout = () => new Promise((res) => {
  setTimeout(() => res(), TIMEOUT);
});

const getClient = () => new Promise((res) => {
  requireScript('https://pay.google.com/gp/p/js/pay.js')
    .then(() => {
      const Client = window.google?.payments?.api?.PaymentsClient;
      res(Client && new Client({ environment }));
    })
    .catch(() => res());
});

export const paymentsClient = () => {
  promise = promise || Promise.race([getClient(), timeout()]);
  return promise;
};

export const allowedPaymentMethods = ({ protocolVersion, publicKey } = {}) => [{
  type: 'CARD',
  parameters: {
    allowedCardNetworks: ['MASTERCARD', 'VISA'],
    allowedAuthMethods: ['CRYPTOGRAM_3DS'],
    allowPrepaidCards: true,
    allowCreditCards: !debitOnlyCountries.includes(countryCode),
    billingAddressRequired: true,
    billingAddressParameters: {
      format: 'MIN',
    },
  },
  ...(protocolVersion && publicKey && {
    tokenizationSpecification: {
      type: 'DIRECT',
      parameters: { protocolVersion, publicKey },
    },
  }),
}];

export const methodData = (integrationAdditionalInfo) => ({
  apiVersion: 2,
  apiVersionMinor: 0,
  merchantInfo: {
    merchantId: 'BCR2DN4TYG4MXERT',
    merchantName: 'BV Gaming Limited',
  },
  allowedPaymentMethods: allowedPaymentMethods(integrationAdditionalInfo),
});

export const isSupported = async () => {
  const client = await paymentsClient();
  if (!client) return false;

  try {
    const response = await client.isReadyToPay(methodData());
    return response?.result || false;
  } catch {
    return false;
  }
};
