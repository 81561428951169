import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isGloballyEnabled } from 'bv-services/features';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

import { selectSession } from 'CashierActions/selectors/deposit';

const NotKyced = ({ session }) => {
  if (!isGloballyEnabled('check_kyc_before_withdraw') || !session || session.isKycVerified) {
    return null;
  }
  return (
    <div className="bvs-msg-box is-warning">
      <p className="bvs-icon is-warning" />
      <h3 className="bvs-msg-box__title">{t('id_verification.deposit_form_text')}</h3>
      <p>
        <a href={bvVar('kyc').helpPage}>
          {t('id_verification.deposit_form_link_text')}
        </a>
      </p>
    </div>
  );
};

NotKyced.propTypes = {
  session: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  session: selectSession,
});

export { NotKyced };

export default connect(mapStateToProps)(NotKyced);
