import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { Carousel } from 'bv-components';

import { operationTypes } from 'CashierCommon/helpers';
import { selectPaymentMethods, getSelectedPaymentMethodId } from 'CashierActions/selectors/deposit';
import { setSelectPaymentMethod, setResult } from 'CashierActions/ducks/deposit_store';
import CashierMenuItem from './cashier_menu_item';
import SelectedPaymentMethod from './selected_payment_method';

const CashierMenu = ({
  paymentMethods,
  setPaymentMethod,
  selectedPaymentMethodId,
  operation,
  setDepositResult,
}) => {
  const [scrollTo, setScrollTo] = useState(null);
  const handleActiveRef = useCallback((item) => {
    if (item) setScrollTo(item);
  });

  const onClickCashierMenuItem = (item) => {
    setDepositResult(null);
    localStorage.setItem(operation, item.id);
    if (item.id !== selectedPaymentMethodId) {
      setPaymentMethod(item);
    }
  };

  if (paymentMethods.length === 1) return <SelectedPaymentMethod {...paymentMethods[0]} />;

  return (
    <Carousel
      scrollStep={234}
      largeArrows
      className="payment-menu-carousel"
      scrollTo={scrollTo}
    >
      <div className="payment-menu">
        {paymentMethods.map((item) => (
          <CashierMenuItem
            key={item.id}
            item={item}
            onClick={() => onClickCashierMenuItem(item)}
            selected={selectedPaymentMethodId === item.id}
            activeRef={handleActiveRef}
          />
        ))}
      </div>
    </Carousel>
  );
};

CashierMenu.propTypes = {
  paymentMethods: PropTypes.instanceOf(Array).isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  selectedPaymentMethodId: PropTypes.number.isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
  setDepositResult: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  paymentMethods: selectPaymentMethods,
  selectedPaymentMethodId: getSelectedPaymentMethodId,
});

const mapDispatchToProps = (dispatch) => ({
  setPaymentMethod: compose(dispatch, setSelectPaymentMethod),
  setDepositResult: compose(dispatch, setResult),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashierMenu);
