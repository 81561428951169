import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { useParams } from 'react-router-dom';
import { FeDepositForm } from 'SharedComponents/cashier/fe_deposit';
import { getAmount } from 'CashierCommon/helpers';
import { fetchQuickAmounts } from 'CashierCommon/api/deposit_api';

const FeDepositLoader = (props) => {
  const [quickAmounts, loading] = useFetch(fetchQuickAmounts);
  const { amount } = useParams();
  const { minAmount } = props;

  if (loading) return <Spinner />;

  return (
    <FeDepositForm
      {...props}
      amount={getAmount(minAmount, amount, quickAmounts?.lastDeposit)}
      quickAmountButtons={quickAmounts?.quickAmounts?.join(',')}
    />
  );
};

FeDepositLoader.propTypes = {
  minAmount: PropTypes.number.isRequired,
};

export default FeDepositLoader;
