import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { MessageBox } from 'bv-components';

const getActions = (isSuccess, isDeposit, errorCode, setResult) => {
  if (isSuccess && isDeposit) {
    return [{
      label: t('payments.deposit.make_new_deposit'),
      inverse: true,
      primary: true,
      onClick: () => setResult(null),
    }];
  }

  if (errorCode === 'PCA-202') {
    return [{
      label: t('quick_deposit.try_again'),
      inverse: true,
      danger: true,
      onClick: () => setResult(null),
    }];
  }

  return [];
};

const ResultMessage = ({
  success: successStr,
  deposit: depositStr,
  title,
  error_code: errorCode,
  error_message: errorMessage,
  'psp_parameters.amount': amount,
  setResult,
}) => {
  const isSuccess = successStr === 'true';
  const isDeposit = depositStr === 'true';
  const operation = isDeposit ? 'deposit' : 'withdraw';

  const titleStr = title || t(`payments.${operation}.${isSuccess ? 'success' : 'failure'}_title`);

  const message = isSuccess ? (
    t(`payments.${operation}.success_message`, {
      amount: toCurrency(amount),
    })
  ) : (
    errorMessage || t(`payments.${operation}.failure_message`)
  );

  return (
    <MessageBox
      {...(isSuccess ? { success: true } : { danger: true })}
      title={titleStr}
      actions={getActions(isSuccess, isDeposit, errorCode, setResult)}
      className="cashier-modal__payment-result"
    >
      <p>{message}</p>
    </MessageBox>
  );
};

export default ResultMessage;

ResultMessage.propTypes = {
  success: PropTypes.bool.isRequired,
  deposit: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setResult: PropTypes.func.isRequired,
  error_code: PropTypes.string,
  error_message: PropTypes.string,
  'psp_parameters.amount': PropTypes.string,
};

ResultMessage.defaultProps = {
  title: null,
  error_code: null,
  error_message: null,
  'psp_parameters.amount': null,
};
