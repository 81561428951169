import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import SecureTransaction from 'SharedComponents/cashier/secure_transaction';
import { fetchWithdrawals as fetchWithdrawalsAction } from 'CashierActions/ducks/withdraw_thunks';
import { fetchPaymentSettings } from 'CashierActions/ducks/deposit_thunks';
import { clearSession as clearSessionAction } from 'CashierActions/ducks/deposit_store';
import CashierMenu from 'CashierActions/components/deposit_page/cashier_menu';
import CashierForm from 'CashierActions/components/deposit_page/cashier_form';
import WalletSelector from 'CashierActions/components/deposit_page/wallet_select';
import WarningMessage from 'CashierActions/components/deposit_page/warning_message';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import PendingWithdraws from './pending_withdraws';
import NoKyced from './not_kyced';

const WithdrawalsPage = ({
  getPaymentSettings,
  fetchWithdrawals,
  clearSession,
}) => {
  const operation = 'withdraw';

  useEffect(() => {
    fetchWithdrawals();
    getPaymentSettings();
    return clearSession;
  }, []);

  return (
    <>
      <WarningMessage operation={operation} />
      <div className="bvs-msg-box payment-form-wrapper">
        <PendingWithdraws operation={operation} />
        <CashierMenu operation={operation} />
        <WalletSelector />
        <NoKyced />
        <CashierForm operation={operation} />
        <SecureTransaction />
      </div>
    </>
  );
};

WithdrawalsPage.propTypes = {
  fetchWithdrawals: PropTypes.func.isRequired,
  getPaymentSettings: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchWithdrawals: compose(dispatch, fetchWithdrawalsAction),
  getPaymentSettings: compose(dispatch, fetchPaymentSettings),
  clearSession: compose(dispatch, clearSessionAction),
});

const modalProps = { tKey: 'withdraw', backUrl: 'bv_cashier/overview' };

export default connect(null, mapDispatchToProps)(withCashierModal(modalProps)(WithdrawalsPage));
