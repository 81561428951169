import { toCurrency } from 'bv-helpers/number';

export const formatAmount = ({ amount, periodLabel, hidePaddedDecimals = false }) => {
  const formattedAmount = toCurrency(amount, {
    decimalPlaces: hidePaddedDecimals ? 0 : 2,
  });

  if (!periodLabel) return formattedAmount;
  return amount ? `${formattedAmount} ${periodLabel}` : periodLabel;
};
