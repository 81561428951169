import { Route, Switch } from 'react-router-dom';

import Payments from './components/payment_list/entry';
import DepositPageModal from './components/deposit_page/deposit_page_modal';
import WithdrawsPage from './components/withdraw_page/entry';
import WithdrawsList from './components/withdraw_page/pending_list';

const CashierActionsApp = () => (
  <Switch>
    <Route path="/bv_cashier/payments" component={Payments} exact />
    <Route path="/bv_cashier/deposit" component={DepositPageModal} exact />
    <Route path="/bv_cashier/withdraw" component={WithdrawsPage} exact />
    <Route path="/bv_cashier/withdraw/pending" component={WithdrawsList} exact />
  </Switch>
);

export default CashierActionsApp;
