import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { MessageBox } from 'bv-components';
import { getPaymentMessage } from 'CashierActions/selectors/deposit';

const WarningMessage = (props) => {
  const { paymentMessage } = props;

  if (!paymentMessage) return null;

  return (
    <MessageBox icon warning big className="cashier-warning-message">
      {paymentMessage.message}
    </MessageBox>
  );
};

WarningMessage.propTypes = {
  paymentMessage: PropTypes.arrayOf(PropTypes.Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  paymentMessage: getPaymentMessage,
});

export default connect(mapStateToProps)(WarningMessage);
