import { createSelector } from 'reselect';

export const withdrawDomain = (state) => state.withdrawStore;

export const selectPendingWithDraws = createSelector(
  withdrawDomain,
  (substate) => substate.withdraws,
);

export const selectLoading = createSelector(
  withdrawDomain,
  (substate) => substate.loading,
);

export const selectPendingAmount = createSelector(
  selectPendingWithDraws,
  (withdraws) => withdraws.reduce((sum, { amount }) => sum + parseFloat(amount), 0).toFixed(2),
);
