import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Modal, Link } from 'bv-components';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';

import { selectSession } from 'CashierActions/selectors/deposit';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const modalActions = (setShowModal) => ([
  {
    label: t('cashier.payments.activation_alert.cancel'),
    reverse: true,
    onClick: () => setFragment('bv_cashier/overview'),
    id: 'cancel-button',
  },
  {
    label: t('cashier.payments.activation_alert.continue'),
    primary: true,
    onClick: () => setShowModal(false),
    id: 'continue-button',
  },
]);

const AcceptMethodChange = ({ session }) => {
  const [showModal, setShowModal] = useState(true);
  const shouldShowModal = session && session.error_code && session.error_code === 'PCC-200';

  useEffect(() => {
    setShowModal(true);
  }, [session]);

  if (!(shouldShowModal && showModal)) return null;

  return (
    <Modal
      title={t('cashier.payments.activation_alert.title')}
      actions={modalActions(setShowModal)}
      warning
    >
      <div className="modal-content">
        <p>{t('cashier.payments.activation_alert.first_phrase')}</p>
        <p>
          {t('cashier.payments.activation_alert.second_phrase')}
          <Link to={CUSTOMER_CONSENT_URLS.privacy_policy}>
            {t('cashier.payments.activation_alert.more_info')}
          </Link>
        </p>
        <p>{t('cashier.payments.activation_alert.third_phrase')}</p>
      </div>
    </Modal>
  );
};

AcceptMethodChange.propTypes = {
  session: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  session: selectSession,
});

export { AcceptMethodChange as AcceptMethodChangeTest };

export default connect(mapStateToProps)(AcceptMethodChange);
