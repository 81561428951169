import { compose } from 'underscore';
import { t } from 'bv-i18n';
import * as api from 'LossLimits/api';
import {
  showFetchError,
  showSuccessMessage,
  LOSS_LIMIT_FORM as FORM,
  NO_LIMIT_PERIOD_ID,
} from 'CashierCommon/helpers';
import {
  setLossLimitConfigs, setLossLimitValues, closeModal,
} from './deposit_store';

export const fetchLossLimitConfigs = () => (dispatch) => (
  api.fetchLimitConfigs()
    .then(compose(dispatch, setLossLimitConfigs))
);

export const fetchLossLimits = () => (dispatch) => (
  Promise.all([
    api.fetchLimitPeriods(),
    api.fetchLimitTypes(),
    api.fetchLimits(),
  ]).then(([periods, types, limits]) => {
    dispatch(setLossLimitValues({
      periods, types, limits,
    }));
  })
);

export const createLossLimit = (values) => async (dispatch) => {
  const { coolOffPeriod, typeId } = values[FORM.fields.config];
  const periodId = values[FORM.fields.period].id;

  let newLimit;

  if (periodId === NO_LIMIT_PERIOD_ID) {
    newLimit = await api.createNoLimitLimit(typeId);
  } else {
    newLimit = await api.createLimit({
      amount: +values[FORM.fields.amount],
      period_id: periodId,
      type_id: typeId,
    });
  }

  if (newLimit?.id) {
    showSuccessMessage(
      <>
        {t('stake_limits.success_submit_modal.title')}
        {coolOffPeriod && newLimit.pending && (
          <p>
            {t('stake_limits.cool_off_period.message', { period: coolOffPeriod })}
          </p>
        )}
      </>,
      () => {
        dispatch(closeModal(FORM.name));
      },
    );
    dispatch(fetchLossLimitConfigs());
    dispatch(fetchLossLimits());
  } else {
    showFetchError();
  }
};
