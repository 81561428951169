import { limitModals } from 'CashierCommon/helpers';

export const SET_WALLET_LIST = 'cashierActions/deposit/SET_WALLET_LIST';
export const SET_PAYMENT_METHOD_LIST = 'cashierActions/deposit/SET_PAYMENT_METHOD_LIST';
export const SET_SELECTED_PAYMENT_METHOD = 'cashierActions/deposit/SET_SELECTED_PAYMENT_METHOD';
export const SET_CASHIER_SESSION = 'cashierActions/deposit/SET_CASHIER_SESSION';
export const SET_SELECTED_WALLET = 'cashierActions/deposit/SET_SELECTED_WALLET';
export const SET_TERM_PAGE = 'cashierActions/deposit/SET_TERM_PAGE';
export const SET_LOSS_LIMITS = 'cashierActions/deposit/SET_LOSS_LIMITS';
export const SET_LOADING = 'cashierActions/deposit/SET_LOADING';
export const SET_PAYMENT_SETTINGS = 'cashierActions/deposit/SET_PAYMENT_SETTINGS';
export const CLEAR_SESSION = 'cashierActions/deposit/CLEAR_SESSION';
export const UPDATE_DEPOSIT_LIMIT_INIT = 'cashierActions/deposit/UPDATE_DEPOSIT_LIMIT_INIT';
export const UPDATE_DEPOSIT_LIMIT_COMPLETE = 'cashierActions/deposit/UPDATE_DEPOSIT_LIMIT_COMPLETE';
export const SET_LOSS_LIMIT_CONFIGS = 'cashierActions/deposit/SET_LOSS_LIMIT_CONFIGS';
export const OPEN_MODAL = 'cashierActions/deposit/OPEN_MODAL';
export const CLOSE_MODAL = 'cashierActions/deposit/CLOSE_MODAL';
export const SET_RESULT = 'cashierActions/deposit/SET_RESULT';

export const setWalletsList = (wallets) => ({
  type: SET_WALLET_LIST,
  wallets,
});

export const setPaymentMethods = (paymentMethods) => ({
  type: SET_PAYMENT_METHOD_LIST,
  paymentMethods,
});

export const setSelectPaymentMethod = (paymentMethod) => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  paymentMethod,
});

export const setCashierSession = (session) => ({
  type: SET_CASHIER_SESSION,
  session,
});

export const setSelectedWalletId = (id) => ({
  type: SET_SELECTED_WALLET,
  id,
});

export const setTermsPage = (termsPage) => ({
  type: SET_TERM_PAGE,
  termsPage,
});

export const setLossLimitConfigs = (lossLimitConfigs) => ({
  type: SET_LOSS_LIMIT_CONFIGS,
  lossLimitConfigs,
});

export const setLossLimitValues = (lossLimits) => ({
  type: SET_LOSS_LIMITS,
  lossLimits,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setPaymentSettings = (settings) => ({
  type: SET_PAYMENT_SETTINGS,
  settings,
});

export const clearSession = () => ({
  type: CLEAR_SESSION,
});

export const updateDepositLimitInit = () => ({
  type: UPDATE_DEPOSIT_LIMIT_INIT,
});

export const updateDepositLimitComplete = () => ({
  type: UPDATE_DEPOSIT_LIMIT_COMPLETE,
});

export const openModal = (name) => ({
  type: OPEN_MODAL,
  name,
});

export const closeModal = (name) => ({
  type: CLOSE_MODAL,
  name,
});

export const setResult = (result) => ({
  type: SET_RESULT,
  result,
});

export const initialState = {
  wallets: [],
  paymentMethods: [],
  session: null,
  selectedWalletId: 0,
  termsPage: {},
  selectedPaymentMethodId: 0,
  loading: true,
  paymentMessages: [],
  depositLimitIsUpdating: false,
  lossLimitConfigs: null,
  lossLimits: null,
  showModal: {
    [limitModals.DEPOSIT_LIMIT]: false,
    [limitModals.LOSS_LIMITS]: false,
  },
  result: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_WALLET_LIST:
      return { ...state, wallets: action.wallets };
    case SET_PAYMENT_METHOD_LIST:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethodId: action.paymentMethod.id,
      };
    case SET_CASHIER_SESSION:
      return { ...state, session: action.session, loading: action.session.success };
    case SET_SELECTED_WALLET:
      return { ...state, selectedWalletId: action.id };
    case SET_TERM_PAGE:
      return { ...state, termsPage: action.termsPage };
    case SET_LOSS_LIMITS:
      return { ...state, lossLimits: action.lossLimits };
    case SET_LOSS_LIMIT_CONFIGS:
      return { ...state, lossLimitConfigs: action.lossLimitConfigs };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_PAYMENT_SETTINGS:
      return {
        ...state,
        paymentMessages: action.settings.genericWarningMessages || [],
      };
    case CLEAR_SESSION:
      return {
        ...state,
        paymentMethods: [],
        session: null,
        result: null,
        selectedPaymentMethodId: 0,
        loading: true,
      };
    case UPDATE_DEPOSIT_LIMIT_INIT:
      return {
        ...state,
        depositLimitIsUpdating: true,
      };
    case UPDATE_DEPOSIT_LIMIT_COMPLETE:
      return {
        ...state,
        depositLimitIsUpdating: false,
      };
    case OPEN_MODAL:
      return {
        ...state,
        result: null,
        showModal: {
          ...state.showModal,
          [action.name]: true,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        result: null,
        showModal: {
          ...state.showModal,
          [action.name]: false,
        },
      };
    case SET_RESULT:
      return { ...state, result: action.result };
    default:
      return state;
  }
};
