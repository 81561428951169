import { createSelector } from 'reselect';
import { property } from 'underscore';

const selectPaymentId = (state, ownProps) => ownProps.match.params.id;

export const paymentDomain = (state) => state.paymentStore;

export const getPayments = createSelector(
  paymentDomain,
  property('payments'),
);

export const getSpinner = createSelector(
  paymentDomain,
  property('showSpinner'),
);

export const selectPaymentData = createSelector(
  [paymentDomain, selectPaymentId],
  (subdomain, paymentId) => {
    const payment = subdomain.payments
      .find((item) => item.id.toString() === paymentId.toString());
    if (!payment) {
      return {};
    }
    const item = Object.assign(payment.attributes, {
      startYear: '',
      endYear: '',
      startMonth: '',
      endMonth: '',
      paysol: payment.paysol_name,
      id: payment.id,
    });
    if (item.startDate) {
      [item.startMonth, item.startYear] = item.startDate.split('/');
    }
    if (item.endDate) {
      [item.endMonth, item.endYear] = item.endDate.split('/');
    }
    return item;
  },
);
