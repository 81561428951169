import PropTypes from 'prop-types';
import { Icon } from 'bv-components';

import { defaultAssetPath } from 'bv';

const DefaultItem = ({
  item,
  name,
  showDeleteConfirm,
}) => (
  <li className="cashier-payments__item">
    <div
      className="cashier-payments__txt"
      style={{ backgroundImage: item.image_url ? `url(${defaultAssetPath(item.image_url)})` : '' }}
    >
      <span className="cashier-payments__txt-name">
        {name || (item.attributes.pan || item.attributes.token)}
      </span>
    </div>
    <button
      type="button"
      className="cashier-payments__icon cashier-payments__icon--delete"
      onClick={showDeleteConfirm}
    >
      <Icon id="trash" type="cashier-payments-trash-icon" />
    </button>
  </li>
);

DefaultItem.defaultProps = {
  name: '',
};

DefaultItem.propTypes = {
  showDeleteConfirm: PropTypes.func.isRequired,
  name: PropTypes.string,
  item: PropTypes.instanceOf(Object).isRequired,
};

export default DefaultItem;
