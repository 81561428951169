import PropTypes from 'prop-types';

const QuickAmountButtons = ({ amounts, action }) => (
  <div className="quick-amount-buttons">
    {amounts.split(',').map((amount) => (
      <span
        key={amount}
        className="quick-amount-buttons__button"
        onClick={() => action(amount)}
      >
        {`+ ${amount}`}
      </span>
    ))}
  </div>
);

QuickAmountButtons.propTypes = {
  amounts: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default QuickAmountButtons;
