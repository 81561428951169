import PropTypes from 'prop-types';
import { Modal, Button } from 'bv-components';
import { t } from 'bv-i18n';
import { allFieldsSelected } from 'DepositLimits/helpers';
import Amounts from 'DepositLimits/components/amounts';
import Period from '../period';

const DepositLimitView = ({
  iconClosable,
  onClose,
  periods,
  amounts,
  selectedPeriodId,
  selectedAmount,
  handlePeriodChange,
  handleAmountChange,
  submitDepositLimit,
  depositLimitIsUpdating,
}) => (
  <Modal
    icon={false}
    actionSheet
    iconClosable={iconClosable}
    onCloseClick={onClose}
    ignoreOverlayClick
  >
    <div className="edit-limit__note">
      <p>{t('account_form.deposit_limit_settings.modal.title')}</p>
      <p>{t('account_form.deposit_limit_settings.modal.description')}</p>
    </div>
    <div className="edit-limit">
      {periods?.length > 1 && (
        <ul className="edit-limit__options">
          {periods.map((period) => (
            <Period
              key={period.id}
              period={period}
              selectedPeriodId={selectedPeriodId}
              onClick={() => handlePeriodChange(period.id)}
            />
          ))}
        </ul>
      )}

      {amounts && (
        <Amounts
          values={amounts}
          selectedAmount={selectedAmount}
          handleChange={handleAmountChange}
        />
      )}

      <div className="bvs-button-group">
        <Button
          className="edit-limit__btn--submit"
          onClick={submitDepositLimit}
          disabled={
            !allFieldsSelected(selectedAmount, selectedPeriodId) || depositLimitIsUpdating
          }
          primary
        >
          {t('save')}
        </Button>
      </div>
    </div>
  </Modal>
);

DepositLimitView.propTypes = {
  iconClosable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedPeriodId: PropTypes.number.isRequired,
  selectedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handlePeriodChange: PropTypes.func.isRequired,
  handleAmountChange: PropTypes.func.isRequired,
  submitDepositLimit: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  depositLimitIsUpdating: PropTypes.bool.isRequired,
};

export default DepositLimitView;
