import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { v as bvVar } from 'bv';

import { fetchDeposits, fetchPaymentSettings } from 'CashierActions/ducks/deposit_thunks';
import { clearSession as clearSessionFn } from 'CashierActions/ducks/deposit_store';

import SecureTransaction from 'SharedComponents/cashier/secure_transaction';
import PendingWithdraws from 'CashierActions/components/withdraw_page/pending_withdraws';
import CashierMenu from './cashier_menu';
import WalletSelect from './wallet_select';
import CashierForm from './cashier_form';
import AcceptMethodChange from './accept_method_change';
import AcceptTerms from './accept_terms';
import DepositLimitContainer from './limits/deposit_limit/deposit_limit_container';
import LossLimitsContainer from './limits/loss_limits/loss_limits_container';
import WarningMessage from './warning_message';

const DepositPage = ({
  getDeposits, getPaymentSettings, clearSession,
}) => {
  const operation = 'deposit';

  useEffect(() => {
    getDeposits();
    getPaymentSettings();
    return clearSession;
  }, []);

  return (
    <>
      <AcceptMethodChange />
      {bvVar('cashierShowTermsAndConditions') && <AcceptTerms />}
      <LossLimitsContainer />
      <DepositLimitContainer />
      <WarningMessage operation={operation} />
      <div className="bvs-msg-box payment-form-wrapper">
        <PendingWithdraws operation={operation} />
        <CashierMenu operation={operation} />
        <WalletSelect />
        <CashierForm operation={operation} />
        <SecureTransaction />
      </div>
    </>
  );
};

DepositPage.propTypes = {
  getDeposits: PropTypes.func.isRequired,
  getPaymentSettings: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getDeposits: compose(dispatch, fetchDeposits),
  getPaymentSettings: compose(dispatch, fetchPaymentSettings),
  clearSession: compose(dispatch, clearSessionFn),
});

export default connect(null, mapDispatchToProps)(DepositPage);
